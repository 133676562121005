import React, { useEffect, useMemo, useState } from 'react';
import { includes, map } from 'lodash';
import useAuth from '../hooks/useAuth';
import useConfig from '../hooks/useConfig';
import TextInput from '../components/inputs/TextInput';
import PickerButton from '../components/buttons/PickerButton';
import ImagePickerCard from '../components/cards/ImagePickerCard';
import LogoPickerCard from '../components/cards/LogoPickerCard';
import CURRENCY_CODES from '../consts/CURRENCY_CODES';
import ConditionalInput from '../components/inputs/ConditionalInput';
import PDFPickerCard from '../components/inputs/PDFPickerCard';
import useNotification from '../hooks/useNotification';
import useGetCompany from '../hooks/useGetCompany';
import { CompanyInfoType, KeyDataType, OptionsType } from '../types/CompanyInfoTypes';
import styles from './CompanyInformation.module.scss';
import InfoModal from '../components/modals/InfoModal';
import PickerModal from '../components/modals/PickerModal';
import NavBar from '../components/layout/NavBar';
import BottomStaticBar from '../components/layout/BottomStaticBar';
import DatePickerCard from '../components/cards/DatePickerCard';
import UnpublishModal from '../components/modals/UnpublishModal';

type ComponentKeyType = 'input' | 'picker' | 'imagePicker' | 'logoImagePicker' | 'moneyInput' | 'datePicker' | 'conditionalInput' | 'pdfPicker';

const renderItemComponens: {
    [key in ComponentKeyType]: React.FC<any>;
} = {
    input: TextInput,
    picker: PickerButton,
    imagePicker: ImagePickerCard,
    logoImagePicker: LogoPickerCard,
    moneyInput: TextInput,
    datePicker: DatePickerCard,
    conditionalInput: ConditionalInput,
    pdfPicker: PDFPickerCard,
};

type PickerCurrencyCodeType = {
    ID: string;
    LABEL: string;
};
const PickerCurrencyCodes: PickerCurrencyCodeType[] = map(CURRENCY_CODES, (item) => ({
    ID: item?.code,
    LABEL: `${item?.code} - ${item?.currency}`,
}));

const CompanyInformation = () => {
    const { handleLogOut } = useAuth();
    const [infoModalText, setInfoModalText] = useState<false | { title: string, subtitle: string | string[] }>(false);
    const { notification, setNotification } = useNotification();
    const { COMPANY_INFO_FIELDS } = useConfig();
    const { userEmail, userId } = useAuth();
    const [showUnpublishModal, setShowUnpublishModal] = useState(false);
    const [showLegalDocumentsModal, setShowLegalDocumentsModal] = useState(false);

    const {
        companyData, setCompanyData, errors, setErrors,
        loading, saveData, validateData, initCompany, publishCompany, publishLoading, canUnpublishCompany, unpublishCompany,
    } = useGetCompany(true, true);

    useEffect(() => {
        initCompany();
    }, []);

    const [selectedPickerId, setSelectedPickerId] = useState<null | string>(null);

    const activePicker: CompanyInfoType | false | undefined = useMemo(() => !!COMPANY_INFO_FIELDS?.length && COMPANY_INFO_FIELDS.find((item) => item.ID === selectedPickerId), [COMPANY_INFO_FIELDS, selectedPickerId]);
    const pickerOptions: OptionsType[] | false | undefined = useMemo(() => {
        if (activePicker && activePicker?.TYPE === 'moneyInput') {
            return PickerCurrencyCodes;
        }
        return !!activePicker && activePicker?.OPTIONS;
    }, [activePicker]);
    const pickerTitle: string | false | undefined = useMemo(() => !!activePicker && activePicker?.LABEL, [activePicker]);

    const pickerSelectedOptions: OptionsType[] | false | undefined = useMemo(() => {
        if (activePicker && activePicker.TYPE === 'moneyInput') {
            const tempData = companyData?.[`${activePicker.KEY}`] as KeyDataType;
            const foundUnit = PickerCurrencyCodes.find((item) => companyData && tempData?.unit === item.ID);
            return foundUnit ? [foundUnit] : [];
        }
        let selectedArr: OptionsType[] = [];
        if (activePicker && activePicker?.SAVE_OPTION_OBJECT) {
            const tempData = companyData?.[`${activePicker.KEY}`] as OptionsType[];
            const itemOptions = activePicker?.OPTIONS as OptionsType[];
            selectedArr = (!!activePicker && itemOptions?.filter((opt) => !!tempData && includes(tempData.map((m) => m.ID) || [], opt.ID))) || [];
        } else if (activePicker) {
            const tempData = companyData?.[`${activePicker.KEY}`] as string[];
            const itemOptions = activePicker?.OPTIONS as OptionsType[];
            selectedArr = (!!activePicker && itemOptions?.filter((opt) => !!tempData && includes(tempData || [], opt.ID))) || [];
        }
        return selectedArr;
    }, [activePicker, companyData]);

    const pickerSelectedIds = useMemo(() => (pickerSelectedOptions?.length && pickerSelectedOptions.map((item: OptionsType | PickerCurrencyCodeType) => item?.ID)) || [], [pickerSelectedOptions]);

    const pickerMaxOptions: number | false | null | undefined = useMemo(() => !!activePicker && activePicker?.MAX_OPTIONS, [activePicker]);

    return (
        <div
            className={styles.container}
        >
            <NavBar />
            {COMPANY_INFO_FIELDS.map((item, index) => {
                if (item.ENABLED === false) {
                    return null;
                }
                if (
                    (!item.SCOPE?.includes('primary') && companyData?.transactionType && (companyData?.transactionType?.[0] === 'eda8a559-d9c2-4f71-8fac-a70564b95aa8')) // primary
                    || (!item.SCOPE?.includes('secondary') && companyData?.transactionType && (companyData?.transactionType?.[0] === '3c81d8ea-2b36-40e2-a183-4d859cb2a563')) // secondary
                ) {
                    return null;
                }
                const showValidation = !!errors?.[`${item?.KEY}`];
                const selected = selectedPickerId === item?.ID;
                const Component = renderItemComponens?.[`${item.TYPE}`];

                let value: string | number | true | string[] | File | { value?: string | undefined; unit?: string | undefined } = '';
                if (item.TYPE === 'input'
                    || item.TYPE === 'imagePicker'
                    || item.TYPE === 'logoImagePicker'
                    || item.TYPE === 'datePicker'
                    || item.TYPE === 'conditionalInput'
                    || item.TYPE === 'pdfPicker'
                ) {
                    value = companyData?.[`${item.KEY}`] || '';
                }
                if (item.TYPE === 'picker' && item.OPTIONS?.length) {
                    let valueObj;
                    if (item?.SAVE_OPTION_OBJECT) {
                        const tempData = companyData?.[`${item.KEY}`] as OptionsType[];
                        const itemOptions = item?.OPTIONS as OptionsType[];
                        valueObj = itemOptions?.filter((opt) => !!tempData && Array.isArray(tempData) && includes(tempData?.map((m) => m?.ID) || [], opt.ID));
                    } else {
                        const tempData = companyData?.[`${item.KEY}`] as string[];
                        const itemOptions = item?.OPTIONS as OptionsType[];
                        valueObj = itemOptions?.filter((opt) => !!tempData && includes(tempData || [], opt?.ID));
                    }
                    value = !!valueObj && valueObj?.map((m) => m.LABEL);
                }
                if (item.TYPE === 'moneyInput') {
                    const tempData = companyData?.[`${item.KEY}`] as KeyDataType;
                    value = tempData?.value || '';
                }

                let unit: string | undefined = '';
                if (item.TYPE === 'moneyInput') {
                    const tempData = companyData?.[`${item.KEY}`] as KeyDataType;
                    unit = tempData?.unit;
                }

                if (Component) {
                    return (
                        <Component
                            key={item.ID}
                            type={item.TYPE}
                            autoCapitalize={item?.AUTO_CAPITALIZE}
                            inputType={item?.WEB_INPUT_TYPE}
                            keyboardType={item?.KEYBOARD_TYPE || 'default'}
                            value={value}
                            selectedUnit={unit}
                            storageKey={item.KEY}
                            setCompanyData={setCompanyData}
                            setErrors={setErrors}
                            companyData={companyData}
                            selected={selected}
                            placeholder={item?.PLACEHOLDER}
                            label={item?.LABEL}
                            conditionLabel={item?.CONDITION_LABEL}
                            defaultValue={item?.DEFAULT_VALUE}
                            inputHeight={item?.INPUT_HEIGHT}
                            info={item?.INFO}
                            optional={item?.OPTIONAL}
                            error={!!showValidation && item?.VALIDATION_ERROR}
                            multiline={item?.MULTILINE}
                            maxLength={item?.MAX_CHARACTERS || undefined}
                            imageWidth={item?.WIDTH}
                            imageHeight={item?.HEIGHT}
                            toggleTitle={item?.TOGGLE_TITLE}
                            iconName={item?.ICON_NAME}
                            iconFamily={item?.ICON_FAMILY}
                            iconSize={item?.ICON_SIZE}
                            disclaimerLabel={item?.DISCLAIMER?.LABEL}
                            disclaimerIconName={item?.DISCLAIMER?.ICON_NAME}
                            disclaimerIconFamily={item?.DISCLAIMER?.ICON_FAMILY}
                            disclaimerIconSize={item?.DISCLAIMER?.ICON_SIZE}
                            companyId={companyData?.id}
                            onInfoPress={() => {
                                if (item?.INFO) {
                                    setInfoModalText({
                                        title: item?.LABEL,
                                        subtitle: item?.INFO,
                                    });
                                }
                            }}
                            onChangeText={(text: string) => {
                                if (item.TYPE === 'input' || item.TYPE === 'conditionalInput') {
                                    setCompanyData((prevData) => ({
                                        ...prevData,
                                        [`${item.KEY}`]: text,
                                    }));
                                } else if (item.TYPE === 'moneyInput') {
                                    const cleanNumbers = (!!text?.length && text.replace(/[^0-9.,]/g, '')) || ''; // needs also to remove the dot if it's the first character, or remove duplicate dots.
                                    const cleanDots = cleanNumbers.replace(/[,]/g, '.').replace(/[.]/g, (val, dotIndex, str) => (dotIndex === str.indexOf('.') ? val : ''));

                                    setCompanyData((prevData) => {
                                        const tempData = prevData?.[`${item.KEY}`] as KeyDataType;
                                        return ({
                                            ...prevData,
                                            [`${item.KEY}`]: {
                                                value: cleanDots,
                                                unit: tempData?.unit || item.DEFAULT_UNIT,
                                            },
                                        });
                                    });
                                } else if (item.TYPE === 'datePicker') {
                                    setCompanyData((prevData) => ({
                                        ...prevData,
                                        [`${item.KEY}`]: text,
                                    }));
                                }
                                setErrors((currentErrors) => ({
                                    ...currentErrors,
                                    [`${item.KEY}`]: false,
                                }));
                            }}
                            onPress={() => {
                                if (item?.TYPE === 'picker' || item?.TYPE === 'moneyInput') {
                                    if (selectedPickerId === item.ID) {
                                        setSelectedPickerId(null);
                                    } else {
                                        setSelectedPickerId(item.ID);
                                    }
                                }
                            }}
                        />
                    );
                }
                return <div key={index} />;
            })}
            <InfoModal
                infoModalText={infoModalText}
                setInfoModalText={setInfoModalText}
            />
            <PickerModal
                showSearch={activePicker && activePicker.SHOW_SEARCH}
                showSelectedTags={activePicker && activePicker.SHOW_SELECTED_TAGS}
                title={pickerTitle}
                pickerMaxOptions={pickerMaxOptions}
                pickerSelectedIds={pickerSelectedIds}
                selectedValues={pickerSelectedOptions}
                onItemPress={(item: OptionsType) => {
                    if (activePicker && activePicker.TYPE === 'picker') {
                        if (activePicker.MULTISELECT) {
                            const isSelected = includes(pickerSelectedIds, item.ID);

                            if (!isSelected && (!activePicker?.MAX_OPTIONS || (pickerSelectedIds?.length < activePicker?.MAX_OPTIONS))) { // select option
                                setCompanyData((prevData) => {
                                    if (activePicker?.SAVE_OPTION_OBJECT) {
                                        return {
                                            ...prevData,
                                            [`${activePicker.KEY}`]: [...pickerSelectedOptions, item],
                                        };
                                    }
                                    return (
                                        {
                                            ...prevData,
                                            [`${activePicker.KEY}`]: [...pickerSelectedIds, item.ID],
                                        }
                                    );
                                });
                            } else { // deselect option
                                setCompanyData((prevData) => {
                                    if (activePicker?.SAVE_OPTION_OBJECT) {
                                        return ({
                                            ...prevData,
                                            [`${activePicker.KEY}`]: [...pickerSelectedOptions].filter((opt) => (opt.ID !== item.ID)),
                                        });
                                    }
                                    return ({
                                        ...prevData,
                                        [`${activePicker.KEY}`]: [...pickerSelectedIds].filter((opt) => opt !== item.ID),
                                    });
                                });
                            }
                        } else { // select unique option
                            setCompanyData((prevData) => ({
                                ...prevData,
                                [`${activePicker.KEY}`]: activePicker?.SAVE_OPTION_OBJECT ? [item] : [item.ID],
                            }));
                            setTimeout(() => {
                                setSelectedPickerId(null);
                            }, 100);
                        }
                        setErrors((currentErrors) => ({
                            ...currentErrors,
                            [`${activePicker.KEY}`]: false,
                        }));
                    }
                    if (activePicker && activePicker.TYPE === 'moneyInput') {
                        setCompanyData((prevData) => {
                            const tempPrevData = prevData?.[`${activePicker.KEY}`] as KeyDataType;
                            return ({
                                ...prevData,
                                [`${activePicker.KEY}`]: {
                                    value: tempPrevData?.value || '',
                                    unit: item.ID,
                                },
                            });
                        });
                        setTimeout(() => {
                            setSelectedPickerId(null);
                        }, 100);
                        setErrors((currentErrors) => ({
                            ...currentErrors,
                            [`${activePicker.KEY}`]: false,
                        }));
                    }
                }}
                visible={!!selectedPickerId}
                handleClose={() => {
                    setSelectedPickerId(null);
                }}
                pickerOptions={pickerOptions}
            />
            <div
                className={styles.publishButton}
                onClick={() => {
                    publishCompany();
                }}
            >
                Publish company
            </div>
            {!!canUnpublishCompany && (
                <div
                    className={styles.unpublishButton}
                    onClick={() => {
                        setShowUnpublishModal(true);
                    }}
                >
                    Unpublish company
                </div>
            )}
            <UnpublishModal
                showUnpublishModal={showUnpublishModal}
                setShowUnpublishModal={setShowUnpublishModal}
                unpublishCompany={unpublishCompany}
            />
            <BottomStaticBar
                loading={loading}
                saveData={saveData}
            />
        </div>
    );
};

export default CompanyInformation;
