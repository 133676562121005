import React, {
    Dispatch, FC, SetStateAction, useEffect, useMemo, useState,
} from 'react';

import { SlClose } from 'react-icons/sl';
import styles from './UnpublishModal.module.scss';
import TextInput from '../inputs/TextInput';
import AuthInput from '../inputs/AuthInput';

interface Props {
    showUnpublishModal: boolean;
    setShowUnpublishModal: Dispatch<SetStateAction<boolean>>;
    unpublishCompany: (reason: string) => void;
}

const UnpublishModal: FC<Props> = ({
    showUnpublishModal, setShowUnpublishModal, unpublishCompany,
}) => {
    const [textInputValue, setTextInputValue] = useState('');

    const [seconds, setSeconds] = useState(5);
    const [intervalId, setIntervalId] = useState<null | ReturnType<typeof setInterval>>(null);

    useEffect(() => {
        if (showUnpublishModal) {
            const interval = setInterval(() => {
                setSeconds((sec) => sec - 1);
            }, 1000);
            setIntervalId(interval);
        }
    }, [showUnpublishModal]);

    useEffect(() => {
        if ((!showUnpublishModal || seconds === 0) && intervalId) {
            clearInterval(intervalId);
        }
        if (!showUnpublishModal) {
            setSeconds(5);
        }
    }, [showUnpublishModal, seconds]);

    const disabled = useMemo(() => seconds > 0 || !textInputValue, [seconds, textInputValue]);

    if (!showUnpublishModal) return null;
    return (
        <div
            className={styles.modal}
            onClick={() => {
                setShowUnpublishModal(false);
                setTextInputValue('');
            }}
        >
            <div
                className={styles.container}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.title}>
                    Are you sure you want to unpublish your company?
                </div>
                <div className={styles.subtitle}>
                    Please let us know why you are unpublishing your company
                </div>
                <div
                    className={styles.closeIconContainer}
                    onClick={() => {
                        setShowUnpublishModal(false);
                        setTextInputValue('');
                    }}
                >
                    <SlClose className={styles.closeIcon} />
                </div>
                <input
                    type="text"
                    className={styles.input}
                    placeholder="Reason"
                    value={textInputValue}
                    onChange={(e) => setTextInputValue(e.target.value)}
                />
                <div
                    className={disabled ? styles.unpublishButtonDisabled : styles.unpublishButton}
                    onClick={() => {
                        if (disabled) return;
                        unpublishCompany(textInputValue);
                        setShowUnpublishModal(false);
                        setTextInputValue('');
                    }}
                >
                    {seconds ? `Unpublish(${seconds})` : 'Unpublish'}
                </div>
            </div>

        </div>
    );
};

export default UnpublishModal;
