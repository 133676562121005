/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { LuCalendarDays } from 'react-icons/lu';
import moment from 'moment';
import styles from './DatePickerCard.module.scss';
import { CompanyInfoInputType } from '../../types/CompanyInfoTypes';
import FieldTitle from '../text/FieldTitle';

interface Props {
    type: CompanyInfoInputType,
    autoCapitalize: any,
    inputType?: 'text' | 'date' | 'number',
    inputStyle: any,
    keyboardType: any,
    value: any,
    selectedUnit: any,
    storageKey: any,
    setCompanyData: any,
    setErrors: any,
    companyData: any,
    selected: any,
    placeholder: any,
    label: any,
    conditionLabel: any,
    defaultValue: any,
    inputHeight: any,
    info: any,
    optional: any,
    error: any,
    multiline: any,
    maxLength: any,
    imageWidth: any,
    imageHeight: any,
    toggleTitle: any,
    iconName: any,
    iconFamily: any,
    iconSize: any,
    disclaimerLabel: any,
    disclaimerIconName: any,
    disclaimerIconFamily: any,
    disclaimerIconSize: any,
    onInfoPress: any,
    onChangeText: any,
    onPress: any,
}

const DatePickerCard: FC<Props> = ({
    type,
    autoCapitalize,
    inputType,
    inputStyle,
    keyboardType,
    value,
    selectedUnit,
    storageKey,
    setCompanyData,
    setErrors,
    companyData,
    selected,
    placeholder,
    label,
    conditionLabel,
    defaultValue,
    inputHeight,
    info,
    optional,
    error,
    multiline,
    maxLength,
    imageWidth,
    imageHeight,
    toggleTitle,
    iconName,
    iconFamily,
    iconSize,
    disclaimerLabel,
    disclaimerIconName,
    disclaimerIconFamily,
    disclaimerIconSize,
    onInfoPress,
    onChangeText,
    onPress,
}) => (
    <div className={styles.container}>
        <FieldTitle
            optional={optional}
            onInfoPress={onInfoPress}
            label={label}
        />
        <div className={styles.inputContainer}>
            <input
                value={moment(value || undefined).format('YYYY-MM-DD')}
                onChange={(e) => {
                    if (inputType === 'date') {
                        const val = e?.target?.value;
                        onChangeText(+moment(val));
                        return;
                    }
                    const val = e?.target?.value;
                    onChangeText(val);
                }}
                style={{ height: inputHeight, color: 'black' }}
                className={styles.input}
                type="date"
            />
            {value ? (
                <div className={styles.datePickerLabel}>
                    {moment(value).format('YYYY-MMMM-DD')}
                </div>
            ) : (
                <div className={styles.placeholder}>
                    {placeholder}
                </div>
            )}
            <LuCalendarDays className={styles.calendarIcon} />
        </div>
        {!!error && (
            <div className={styles.error}>
                {error}
            </div>
        )}
    </div>
);

export default DatePickerCard;
