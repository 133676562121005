import React, {
    createContext, useEffect, useMemo, useState, SetStateAction, Dispatch, FC,
} from 'react';
import {
    User, NextOrObserver, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail,
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase/firebase';
import { USERS } from '../consts/COLLECTIONS';

const AuthContext = createContext<{
    userId: string | null | undefined
    setUserId: Dispatch<SetStateAction<string | null | undefined>>
    userEmail: string | null | undefined
    setUserEmail: Dispatch<SetStateAction<string | null | undefined>>
    isLoggedIn: boolean | null
    initializing: boolean
    handleRegistration: () => void
    userInfo: {
        fullName: string,
        email: string,
        password: string,
    }
    setUserInfo: Dispatch<SetStateAction<{
        fullName: string,
        email: string,
        password: string,
    }>>
    handleLogIn: (userInfo?: {
        email: string,
        password: string,
    }) => void
    handleLogOut: () => void
    error: AuthenticationErrorType
    clearErrors: () => void
    setError: Dispatch<SetStateAction<AuthenticationErrorType>>
    updateError: (key: 'fullName' | 'email' | 'password' | 'firebase', value: string | null) => void
    loading: boolean
    setLoading: Dispatch<SetStateAction<boolean>>
    userData: User | null
    shouldLogin: boolean
    resetPassword: () => void
} | null>(null);

export type AuthenticationErrorType = {
    fullName: string | null,
    email: string | null,
    password: string | null,
    firebase: string | null,
};

const { Provider } = AuthContext;

export const AuthProvider: FC<any> = ({ children }) => {
    const [userInfo, setUserInfo] = useState({
        fullName: '',
        email: '',
        password: '',
    });

    const [userId, setUserId] = useState<string | null | undefined>(null);
    const [userEmail, setUserEmail] = useState<string | null | undefined>(null);

    const [isLoggedIn, setisLoggedIn] = useState<boolean | null>(null);
    const [initializing, setInitializing] = useState(true);
    const [userData, setUserData] = useState<null | User>(null);
    const [error, setError] = useState<AuthenticationErrorType>({
        fullName: null,
        email: null,
        password: null,
        firebase: null,
    });
    const [loading, setLoading] = useState(false);
    // const [showLogoutBanner, setShowLogoutBanner] = useState(false);

    const shouldLogin = useMemo(() => initializing || !userId || !userEmail, [userId, userEmail, initializing]);

    const initUser: NextOrObserver<User> = (user) => {
        setisLoggedIn(!!user);
        setUserId(user?.uid);
        setUserEmail(user?.email);
        setUserData(user);
        if (initializing) setInitializing(false);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, initUser);
        return unsubscribe; // unsubscribe on unmount
    }, []);

    const updateError = (key: 'fullName' | 'email' | 'password' | 'firebase', value: string | null) => {
        setError((prevVal) => ({
            ...prevVal,
            [key]: value,
        }));
        setLoading(false);
    };

    const clearErrors = () => {
        setError({
            fullName: null,
            email: null,
            password: null,
            firebase: null,
        });
    };

    const clearUserInfo = () => {
        setUserInfo({
            fullName: '',
            email: '',
            password: '',
        });
    };

    const handleRegistration = async () => {
        setLoading(true);
        if (!userInfo?.fullName || !(userInfo?.fullName?.replace(/\s/g, '')?.length > 1)) {
            updateError('fullName', 'Please add your full name');
            // if (errorCallBack) {
            //     errorCallBack('Please add your full name');
            // }
            return;
        }
        if (!userInfo?.email) {
            updateError('email', 'Please add your email');
            // if (errorCallBack) {
            //     errorCallBack('Please add your email');
            // }
            return;
        }
        if (
            !userInfo?.password
            || !userInfo?.password?.match(/[A-Z]/g)
            || !userInfo?.password?.match(/[0-9]/g
                || !(userInfo?.password?.length >= 8))
        ) {
            // if (errorCallBack) {
            //     errorCallBack('Invalid password');
            // }
            updateError(
                'password',
                'Password should have:\n\u2022 At least 8 characters\n\u2022 One or more numbers\n\u2022 One or more capital letters',
            );
            return;
        }

        try {
            const response = await createUserWithEmailAndPassword(auth, userInfo?.email, userInfo?.password);
            if (response?.user?.uid) {
                setDoc(doc(firestore, USERS, response?.user?.uid), {
                    fullName: userInfo?.fullName,
                    email: userInfo?.email?.toLowerCase(),
                    fromWeb: true,
                    id: response?.user?.uid,
                });
                clearUserInfo();
            }
        } catch (err: any) {
            if (err?.code === 'auth/email-already-in-use') {
                // An account already exists with the email
                updateError('email', 'This email address is already in use');
                // if (errorCallBack) {
                //     errorCallBack('auth/email-already-in-use');
                // }
            } else if (err?.code === 'auth/invalid-email') {
                updateError('email', 'Please add a valid email');
                // if (errorCallBack) {
                //     errorCallBack('auth/invalid-email');
                // }
            } else {
                updateError('firebase', `${err}`);
                console.log('Email/password creation failed', err);
                // if (errorCallBack) {
                //     errorCallBack('Email/password creation failed');
                // }
            }
        }
    };

    const handleLogIn = () => {
        setLoading(true);
        if (!userInfo?.email && !userInfo?.password) {
            updateError('email', 'Please add your email and password');
            return;
        }
        if (!userInfo?.email) {
            updateError('email', 'Please add your email');
            return;
        }
        if (!userInfo?.password) {
            updateError('email', 'Please add your password');
            return;
        }

        try {
            signInWithEmailAndPassword(auth, userInfo?.email, userInfo?.password)
                .then(() => {
                    console.log('User Logged in!');
                    setLoading(false);
                    clearUserInfo();
                })
                .catch((err) => {
                    updateError('firebase', 'Incorrect email or password');
                    setLoading(false);
                    console.error(err);
                    // if (errorCallBack) {
                    //     errorCallBack();
                    // }
                });
        } catch (err) {
            console.error(err);
            // if (errorCallBack) {
            //     errorCallBack();
            // }
        }
    };

    const handleLogOut = () => {
        setUserId(null);
        setUserEmail(null);
        setisLoggedIn(false);
        setInitializing(false);
        setUserData(null);
        setError({
            fullName: null,
            email: null,
            password: null,
            firebase: null,
        });
        setLoading(false);
        // setShowLogoutBanner(true);
        // setTimeout(() => {
        //     setShowLogoutBanner(false);
        // }, 3000);
        signOut(auth).then(() => {
            console.log('User signed out!');
        });
    };

    const resetPassword = () => {
        setLoading(true);
        try {
            if (!userInfo?.email?.length) {
                setLoading(false);
                updateError('firebase', 'Please add your email address');
                return;
            }
            sendPasswordResetEmail(auth, userInfo?.email).then(() => {
                console.log('Email sent');
                setLoading(false);
                updateError('firebase', 'Reset email sent. Please check your inbox for the reset link.');
            }).catch((err) => {
                updateError('firebase', 'Email not sent. Please check your email address and try again.');
                setLoading(false);
                console.error(err);
            });
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    return (
        <Provider value={{
            userId,
            setUserId,
            userEmail,
            setUserEmail,
            isLoggedIn,
            initializing,
            handleRegistration,
            handleLogIn,
            handleLogOut,
            error,
            setError,
            updateError,
            loading,
            setLoading,
            userData,
            shouldLogin,
            clearErrors,
            userInfo,
            setUserInfo,
            resetPassword,
        }}
        >
            {children}
        </Provider>
    );
};

export default AuthContext;
