import React, { FC } from 'react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import FieldTitle from '../text/FieldTitle';
import styles from './PickerButton.module.scss';

interface Props {
    selected: boolean,
    error: false | string
    label: string
    value: string[] | undefined
    placeholder: string | undefined
    onPress: () => void
    info?: string | string[]
    onInfoPress?: () => void
    optional?: boolean
}

const PickerButton: FC<Props> = ({
    selected, error, label, value, placeholder, onPress, info, onInfoPress, optional,
}) => (
    <div
        className={styles.container}
    >
        <FieldTitle
            optional={optional}
            onInfoPress={onInfoPress}
            label={label}
        />
        <div
            className={styles.hoverContainer}
        >
            <div
                className={styles.button}
                onClick={onPress}
            >
                {(Array.isArray(value) && !!value?.length) ? (
                    <div
                        className={styles.textContainer}
                    >
                        {value.map((item, index) => (
                            <div
                                key={item}
                                className={styles.text}
                            >
                                {`${item}${index < value.length - 1 ? ',' : ''}`}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div
                        className={styles.placeholder}
                    >
                        {placeholder}
                    </div>
                )}
                {selected ? <IoChevronUp className={styles.icon} /> : <IoChevronDown className={styles.icon} />}
            </div>
        </div>
        {!!error && (
            <div className={styles.error}>
                {error}
            </div>
        )}
    </div>
);

export default PickerButton;
