import React, {
    Dispatch, FC, SetStateAction, useEffect, useState,
} from 'react';
import { FaRegFileImage } from 'react-icons/fa';
import { CiEdit } from 'react-icons/ci';
import { CompanyInfoDataType, CompanyInfoKeyType } from '../../types/CompanyInfoTypes';
import FieldTitle from '../text/FieldTitle';
import styles from './LogoPickerCard.module.scss';

interface Props {
    error: false | string
    label: string
    value: string[] | undefined
    placeholder: string | undefined
    info?: string
    onInfoPress?: () => void
    imageWidth?: number
    imageHeight?: number
    setCompanyData: React.Dispatch<React.SetStateAction<CompanyInfoDataType>>
    storageKey?: CompanyInfoKeyType
    optional?: boolean
    setErrors: Dispatch<SetStateAction<CompanyInfoDataType>>
}

const LogoPickerCard: FC<Props> = ({
    error, label, onInfoPress, info, value, storageKey,
    placeholder, imageHeight, imageWidth, setCompanyData, optional, setErrors,
}) => {
    const [selectedImage, setSelectedImage] = useState<string | ArrayBuffer | null>(null);
    const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
                setSelectedImageFile(file);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (selectedImageFile) {
            setCompanyData((prevData) => ({
                ...prevData,
                [`${storageKey}`]: null,
                [`${storageKey}_local`]: selectedImageFile,
            }));
            setErrors((currentErrors) => ({
                ...currentErrors,
                [`${storageKey}`]: false,
            }));
        }
    }, [selectedImageFile]);

    return (
        <div
            className={styles.container}
        >
            <FieldTitle
                onInfoPress={onInfoPress}
                label={label}
            />
            {typeof selectedImage === 'string' || typeof value?.[0] === 'string' ? (
                <div
                    className={styles.imageContainer}
                >
                    <img
                        src={selectedImage as string || value?.[0]}
                        className={styles.image}
                        alt="Selected"
                    />
                    <label
                        htmlFor={storageKey}
                        className={styles.editInputLabel}
                    >
                        <CiEdit />
                        Edit
                    </label>
                </div>
            ) : (
                <label
                    htmlFor={storageKey}
                    className={styles.placeholderContainer}
                >
                    <FaRegFileImage
                        size={50}
                        color="#9DA3AE"
                    />
                    <div className={styles.placeholder}>
                        {placeholder}
                    </div>
                    <div
                        className={styles.inputLabel}
                    >
                        Browse...
                    </div>
                </label>
            )}
            <input
                id={storageKey}
                className={styles.input}
                type="file"
                accept="image/*"
                onChange={handleImageChange}
            />
            {!!error && (
                <div className={styles.error}>
                    {error}
                </div>
            )}
        </div>
    );
};

export default LogoPickerCard;
