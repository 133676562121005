import React, { FC } from 'react';
import Text from '../text/Text';
import styles from './SelectionTabs.module.scss';

interface Props {
    tabs: string[]
    activeTab: number
    setSelectedTab: (index: number) => void
}

const SelectionTabs: FC<Props> = ({
    tabs,
    activeTab,
    setSelectedTab,
}) => (
    <div
        className={styles.container}
    >
        {tabs.map((item, index) => (
            <div
                className={styles.tab}
                key={item}
                onClick={() => {
                    setSelectedTab(index);
                }}
            >
                <Text
                    white
                    title
                >
                    {item}
                </Text>
                {activeTab === index && (
                    <div
                        className={styles.underline}
                    />
                )}
            </div>
        ))}
    </div>
);

export default SelectionTabs;
