import React, { FC } from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import styles from './FieldTitle.module.scss';
import Text from './Text';

interface Props {
    onInfoPress: any,
    label: string
    optional?: boolean
}

const FieldTitle: FC<Props> = ({ onInfoPress, label, optional }) => (
    <div
        className={styles.titleContainer}
        onClick={onInfoPress}
    >
        <Text
            subtitle
            white
            style={{ marginTop: 10 }}
        >
            {`${!optional ? '*' : ''}${label}`}
        </Text>
        <IoInformationCircleOutline className={styles.infoIcon} />
    </div>
);

export default FieldTitle;
