import React, { Dispatch, FC, useState } from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { CompanyInfoDataType, CompanyInfoKeyType } from '../../types/CompanyInfoTypes';
import useAuth from '../../hooks/useAuth';
import FieldTitle from '../text/FieldTitle';
import styles from './ConditionalInput.module.scss';

interface Props {
    error: false | string
    label: string
    onInfoPress: any,
    info: any,
    onPress: () => void
    conditionLabel: string
    defaultValue: 'userEmail' | string,
    companyData: CompanyInfoDataType
    storageKey: CompanyInfoKeyType
    setCompanyData: Dispatch<React.SetStateAction<CompanyInfoDataType>>
    setErrors: Dispatch<React.SetStateAction<CompanyInfoDataType>>
    optional?: boolean
    onChangeText: any
    value: string[] | undefined
}

const ConditionalInput: FC<Props> = ({
    onPress,
    error,
    label,
    onInfoPress,
    info,
    conditionLabel,
    defaultValue,
    companyData,
    storageKey,
    setCompanyData,
    setErrors,
    optional,
    onChangeText,
    ...restProps
}) => {
    const textInputRef = React.createRef<HTMLInputElement>();
    const { userEmail } = useAuth();
    const [hideInput, setHideInput] = useState(true);

    return (
        <div className={styles.container}>
            <FieldTitle
                onInfoPress={onInfoPress}
                label={label}
                optional={optional}
            />
            <div
                className={styles.contentContainer}
                onClick={() => {
                    setHideInput((currentValue) => {
                        if (!currentValue) {
                            setCompanyData((prevData) => ({
                                ...prevData,
                                [`${storageKey}`]: defaultValue === 'userEmail' ? userEmail : defaultValue || '',
                            }));
                            setErrors((currentErrors) => ({
                                ...currentErrors,
                                [`${storageKey}`]: false,
                            }));
                        }
                        return !currentValue;
                    });
                }}
            >
                <div className={styles.conditionLabel}>{conditionLabel}</div>
                {hideInput ? <MdCheckBox className={styles.checkIcon} /> : <MdCheckBoxOutlineBlank className={styles.checkIcon} />}
            </div>
            {!hideInput && (
                <div
                    className={styles.textInputContainer}
                >
                    <input
                        ref={textInputRef}
                        className={styles.textInput}
                        onChange={(e) => {
                            const val = e?.target?.value;
                            onChangeText(val);
                        }}
                    />
                </div>
            )}
            {!!error && (
                <div className={styles.error}>
                    {error}
                </div>
            )}
        </div>
    );
};

export default ConditionalInput;
