import React, { FC } from 'react';
import Lottie from 'react-lottie';
import styles from './BottomStaticBar.module.scss';
import WhiteLoader from '../../assets/lottie/WhiteLoader.json';

interface Props {
    saveData: () => void;
    loading: boolean;
    disabled?: boolean;
}

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: WhiteLoader,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const BottomStaticBar: FC<Props> = ({ saveData, loading, disabled }) => (
    <div
        className={styles.container}
    >
        <button
            type="submit"
            className={styles.saveButton}
            onClick={() => {
                if (!disabled && saveData) {
                    saveData();
                }
            }}
        >
            {loading ? (
                <Lottie
                    options={defaultOptions}
                    height="50px"
                    width="50px"
                    isStopped={false}
                    isPaused={false}
                />
            ) : 'Save progress'}
        </button>
    </div>
);

export default BottomStaticBar;
