/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext } from 'react';
import RemoteConfigContext from '../context/RemoteConfigContext';
import { CompanyInfoType } from '../types/CompanyInfoTypes';

type TemplateIconName = 'string'
type AnalyticsEventName = 'string'

export type ToggleSubsectionsType = {
    ID: string,
    NAME: string,
    KEY: UserDataKeyType,
    INFO: string,
    ICON: TemplateIconName
    ANALYTICS_EVENT?: AnalyticsEventName,
};

export type UserDataKeyType = 'fullName'
    | 'notifications'
    | 'presentationViewsNotifications'
    | 'followerUpdatesNotifications'
    | 'newListingAlertsNotifications'
    | 'tiltToOpenPresentation'
    | 'appFeedback'
    | 'didShowRegisterDocuments'
    | 'didShowInvestorDocuments'
    | 'isTesting';

export type IndustryType = {
    ID: string,
    GROUP_ID: string,
    LABEL: string
    FILTER_GROUP_ID: string
};

export type CompanyFilterType = 'buttons' | 'picker';
export type CompanyFilterOptionType = {
    ID: string,
    LABEL: string,
    EQUIVALENT?: string[]
    ACTIVE: boolean,
};

export type LegalType = {
    TITLE: string
    SUBTITLE: string
    SUBTITLE_2: string
    FOOTER: string
    CTA: string
    SECONDARY_CTA?: string
    WITH_BACK: boolean
    WITH_LOGOUT: boolean
    ANALYTICS_EVENT?: AnalyticsEventName
    DOCUMENTS: {
        ID: string,
        NAME: string,
        URI: string,
        BOLD?: boolean,
        ICON: TemplateIconName
    }[]
};

const useConfig = () => {
    const context = useContext(RemoteConfigContext);
    if (!context) {
        throw new Error('AuthContext must be used within a AuthProvider');
    }

    const { config } = context;

    // industries
    const INDUSTRIES: IndustryType[] = config?.industries || [];

    // @ts-ignore
    const DEFAULT_COMPANY_INFO_FIELDS: CompanyInfoType[] = config?.companyInfo?.INFO_FIELDS as CompanyInfoType[] || [];

    const COMPANY_INFO_FIELDS = INDUSTRIES?.length ? (
        DEFAULT_COMPANY_INFO_FIELDS.map((item) => {
            if (item?.ID === '8e153dcf-75d6-4113-9188-53cf865b268a0') {
                return ({
                    ...item,
                    OPTIONS: INDUSTRIES,
                });
            }
            return item;
        })
    ) : DEFAULT_COMPANY_INFO_FIELDS;

    // registration Legal
    const REGISTRATION_LEGAL: LegalType = config?.legal?.REGISTRATION_LEGAL as LegalType || {};

    // investor legal
    const INVESTOR_LEGAL: LegalType = config?.legal?.INVESTOR_LEGAL as LegalType || {};

    return ({
        COMPANY_INFO_FIELDS,
        INDUSTRIES,
        REGISTRATION_LEGAL,
        INVESTOR_LEGAL,
    });
};

export default useConfig;
