import React, { FC } from 'react';
import { FaCheck } from 'react-icons/fa6';
import styles from './PickerOption.module.scss';

interface Props {
    onPress: () => void,
    title: string,
    selected: boolean,
    searchTerm?: string
}

const PickerOption: FC<Props> = ({
    title, selected, onPress, searchTerm,
}) => {
    const cleanSearchTerm = searchTerm && searchTerm.replace(/[^a-zA-Z0-9 ]/g, '');

    const highlightRegex = new RegExp(`${cleanSearchTerm}`, 'gi');

    const splitTitle = cleanSearchTerm && title?.replace(highlightRegex, (text) => (`\u2042\u2051${text}\u2051\u2042`)).split('\u2042');

    const arrayTitle = (splitTitle && splitTitle.map((item, index) => {
        if (item.indexOf('\u2051') > -1) {
            return (
                <span
                    key={`${item}-${index}`}
                    className={styles.foundText}
                >
                    {item.replace(/\u2051/g, '')}
                </span>
            );
        }
        return item;
    })) || title;

    return (
        <div
            className={selected ? styles.selectedContainer : styles.container}
            onClick={onPress}
        >
            <div className={styles.title}>
                {arrayTitle}
            </div>
            {!!selected && (
                <FaCheck className={styles.icon} />
            )}
        </div>
    );
};

export default PickerOption;
