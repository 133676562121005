import React from 'react';
import Lottie from 'react-lottie';
import WhiteLoader from '../../assets/lottie/WhiteLoader.json';
import ColouredLoader from '../../assets/lottie/ColouredLoader.json';
import styles from './Button.module.scss';

interface Props {
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    children?: React.ReactElement | string | null
    onClick?: () => void;
    loading?: boolean;
    coloredLoader?: boolean;
    style?: React.CSSProperties;
}

export const BUTTON_HEIGHT = '56px';

const Button: React.FC<Props> = ({
    type = 'button',
    disabled,
    children,
    onClick,
    loading,
    coloredLoader,
    ...restProps
}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: coloredLoader ? ColouredLoader : WhiteLoader,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <button
            type={type}
            className={styles.button}
            onClick={() => {
                if (!disabled && onClick) {
                    onClick();
                }
            }}
            {...restProps}
        >
            {loading ? (
                <Lottie
                    options={defaultOptions}
                    height="50px"
                    width="50px"
                    isStopped={false}
                    isPaused={false}
                />
            ) : children}
        </button>
    );
};
export default Button;
