import React, { FC } from 'react';
import styles from './Text.module.scss';

interface Props {
    style?: React.CSSProperties
    largeTitle?: boolean
    title?: boolean
    subtitle?: boolean
    white?: boolean
    accent?: boolean
    primary?: boolean
    primaryDark?: boolean
    children?: React.ReactElement | string | null
}

const Text: FC<Props> = ({
    largeTitle, title, subtitle, white, accent, primary, primaryDark, style = {}, children, ...restProps
}) => {
    const classNames = [
        styles.regular, // always include regular style
        largeTitle && styles.largeTitle,
        title && styles.title,
        subtitle && styles.subtitle,
        white && styles.white,
        accent && styles.accent,
        primary && styles.primary,
        primaryDark && styles.primaryDark,
    ].filter(Boolean).join(' ');

    return (
        <div
            className={classNames}
            style={{
                ...style,
            }}
            {...restProps}
        >
            {children}
        </div>
    );
};

export default Text;
