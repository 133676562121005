import React, { MouseEventHandler } from 'react';
import './Box.scss';
import type { Property } from 'csstype/index';

export interface BoxProps {
    children?: React.ReactNode,
    center?: boolean,
    vCenter?: boolean,
    hCenter?: boolean,
    selfCenter?: boolean,
    spaceBetween?: boolean,
    flex?: boolean | number,
    flexGrow?: boolean,
    flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse',
    row?: boolean,
    column?: boolean,
    mAll?: string | number | false,
    mt?: string | number | false,
    mb?: string | number | false,
    ml?: string | number | false,
    mr?: string | number | false,
    pAll?: string | number | false,
    pl?: string | number | false,
    pt?: string | number | false,
    pr?: string | number | false,
    pb?: string | number | false,
    width?: string | number | false,
    minWidth?: string | number | false,
    height?: string | number | false,
    minHeight?: string | number | false,
    borderRadius?: number,
    borderBottomLeftRadius?: number | false,
    borderBottomRightRadius?: number | false,
    borderTopLeftRadius?: number | false,
    borderTopRightRadius?: number | false,
    justifyContent?: Property.JustifyContent | false,
    alignSelf?: string | false,
    backgroundColor?: string,
    color?: string,
    aspectRatio?: number,
    borderWidth?: number | false,
    borderStyle?: 'solid' | 'dotted' | 'dashed' | false,
    borderBottomWidth?: number | false,
    borderColor?: string | false,
    absolute?: boolean,
    top?: string | number | string,
    bottom?: string | number | string,
    left?: string | number | string,
    right?: string | number | string,
    opacity?: number,
    zIndex?: number,
    onPress?: MouseEventHandler<HTMLDivElement> | undefined,
    onClick?: MouseEventHandler<HTMLDivElement> | undefined,
    disabled?: boolean,
    position?: string,
    selfStart?: boolean,
    selfEnd?: boolean,
    overflowHidden?: boolean,
    relative?: boolean,
    size?: string,
}

const Box: React.FC<BoxProps> = ({
    children,
    center,
    vCenter,
    hCenter,
    spaceBetween,
    flex,
    flexGrow,
    flexWrap,
    row,
    column,
    mAll,
    mt,
    mb,
    ml,
    mr,
    pAll,
    pl,
    pt,
    pr,
    pb,
    width,
    minWidth,
    height,
    minHeight,
    borderRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    justifyContent,
    backgroundColor,
    aspectRatio,
    borderWidth,
    borderStyle,
    borderBottomWidth,
    borderColor,
    absolute,
    top,
    bottom,
    left,
    right,
    opacity,
    zIndex,
    onPress,
    onClick,
    disabled,
    color,
    selfCenter,
    selfStart,
    selfEnd,
    overflowHidden,
    relative,
    size,
    ...restProps
}) => (
    <div
        className={
            `${center ? 'center ' : ''}
            ${hCenter ? 'hCenter ' : ''}
            ${vCenter ? 'vCenter ' : ''}
            ${spaceBetween ? 'spaceBetween ' : ''}
            ${row ? 'row ' : ''}
            ${column ? 'column ' : ''}
            ${selfCenter ? 'selfCenter ' : ''}
            ${selfStart ? 'selfStart ' : ''}
            ${selfEnd ? 'selfEnd ' : ''}
            ${overflowHidden ? 'overflowHidden ' : ''}
            ${absolute ? 'absolute ' : ''}
            ${relative ? 'relative ' : ''}
            ${disabled ? 'disabled ' : ''}
            ${flex ? 'flex ' : ''}`
        }
        style={{
            backgroundColor,
            color,
            justifyContent: justifyContent || undefined,
            flexGrow: flexGrow === true ? 1 : undefined,
            flexWrap,

            margin: mAll || undefined,
            marginTop: mt || undefined,
            marginBottom: mb || undefined,
            marginLeft: ml || undefined,
            marginRight: mr || undefined,

            padding: pAll || undefined,
            paddingTop: pt || undefined,
            paddingBottom: pb || undefined,
            paddingLeft: pl || undefined,
            paddingRight: pr || undefined,

            top: top || undefined,
            bottom: bottom || undefined,
            left: left || undefined,
            right: right || undefined,

            width: width || undefined,
            minWidth: minWidth || undefined,
            height: height || undefined,
            minHeight: minHeight || undefined,

            aspectRatio,
            borderWidth: borderWidth || undefined,
            borderStyle: borderStyle || undefined,
            borderBottomWidth: borderBottomWidth || undefined,
            borderColor: borderColor || undefined,
            borderRadius: borderRadius || undefined,
            borderBottomLeftRadius: borderBottomLeftRadius || undefined,
            borderBottomRightRadius: borderBottomRightRadius || undefined,
            borderTopLeftRadius: borderTopLeftRadius || undefined,
            borderTopRightRadius: borderTopRightRadius || undefined,
            zIndex,
            opacity,

            fontSize: size ? `${size}px` : undefined,
        }}
        onClick={disabled ? undefined : onPress || onClick}
        {...restProps}
    >
        {children}
    </div>
);

export default Box;
