import React, { FC, InputHTMLAttributes, useState } from 'react';
import { IoEyeOffSharp, IoEyeSharp } from 'react-icons/io5';
import styles from './AuthInput.module.scss';
import Text from '../text/Text';

type Props = InputHTMLAttributes<HTMLInputElement>

const AuthInput: FC<Props> = ({ title, ...restProps }) => {
    const [visible, setVisible] = useState(false);
    return (
        <div
            className={styles.container}
        >
            <Text
                subtitle
                white
                style={{ marginTop: 10 }}
            >
                {title}
            </Text>
            <div
                className={styles.authInputContainer}
            >
                <input
                    className={styles.authInput}
                    {...restProps}
                    type={restProps.type === 'password' && visible ? 'text' : restProps.type}
                />
                {restProps.type === 'password' && (
                    <div
                        className={styles.eyeContainer}
                        onClick={() => setVisible(!visible)}
                    >
                        {visible ? (
                            <IoEyeOffSharp />
                        ) : (
                            <IoEyeSharp />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AuthInput;
