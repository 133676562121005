import companyInfo from './companyInfo.json';
import industries from './industries.json';
import legal from './legal.json';

export type ConfigTypeKeys = 'companyInfo' | 'industries' | 'legal';

export type ConfigType = {
    companyInfo: typeof companyInfo,
    industries: typeof industries,
    legal: typeof legal,
};

const config: ConfigType = {
    companyInfo,
    industries,
    legal,
};

export default config;
