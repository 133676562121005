import React, {
    FC, useEffect, useMemo, useState,
} from 'react';
import { includes, lowerCase } from 'lodash';
import { SlClose } from 'react-icons/sl';
import { IoClose } from 'react-icons/io5';
import styles from './PickerModal.module.scss';
import { OptionsType } from '../../types/CompanyInfoTypes';
import PickerOption from '../buttons/PickerOption';

interface Props {
    title: string | false | undefined
    visible: boolean,
    handleClose: () => void
    pickerOptions: OptionsType[] | false | undefined,
    onItemPress: (item: OptionsType, index: number) => void
    pickerSelectedIds: string[] | undefined | false
    pickerMaxOptions: number | null | false | undefined
    showSearch?: boolean
    // upperFirstSearch?: boolean
    selectedValues?: OptionsType[] | false
    showSelectedTags?: boolean,
    // onInfoPress?: () => void
}

const PickerModal: FC<Props> = ({
    showSearch,
    showSelectedTags,
    title,
    pickerMaxOptions,
    pickerSelectedIds,
    selectedValues,
    onItemPress,
    visible,
    handleClose,
    pickerOptions,
}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredPickerOptions = useMemo(() => pickerOptions && pickerOptions?.length && pickerOptions.filter((item) => includes(lowerCase(item.ID), lowerCase(searchTerm)) || includes(lowerCase(item.LABEL), lowerCase(searchTerm))), [searchTerm, pickerOptions]);

    useEffect(() => {
        if (!visible) {
            setSearchTerm('');
        }
    }, [visible]);

    if (!visible) {
        return null;
    }

    return (
        <div
            className={styles.modal}
            onClick={() => handleClose()}
        >
            <div
                className={styles.container}
                onClick={(e) => e.stopPropagation()}
            >
                <div
                    className={styles.titleContainer}
                >
                    <div className={styles.title}>
                        {title}
                    </div>
                    {!!pickerMaxOptions && (
                        <div className={styles.pickerMaxOptions}>
                            {`${((pickerSelectedIds && pickerSelectedIds?.length) || 0)}/${pickerMaxOptions}`}
                        </div>
                    )}
                </div>
                <div>
                    {showSearch && (
                        <input
                            className={styles.searchInput}
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    )}
                </div>
                {!!showSelectedTags && Array.isArray(selectedValues) && !!selectedValues?.length && (
                    <div className={styles.selectedValuesContainer}>
                        {selectedValues.map((item, index) => (
                            <div
                                key={item.ID}
                                className={styles.selectedValue}
                                onClick={() => {
                                    onItemPress(item, index);
                                }}
                            >
                                <div className={styles.selectedValueText}>
                                    {item.LABEL}
                                </div>
                                <IoClose className={styles.selectedCloseIcon} />
                            </div>
                        ))}
                    </div>
                )}
                <div
                    className={styles.scrollContent}
                >
                    {filteredPickerOptions && filteredPickerOptions.map((item, index) => (
                        <PickerOption
                            key={item.ID}
                            searchTerm={searchTerm}
                            selected={includes(pickerSelectedIds || [], item.ID)}
                            title={item?.LABEL}
                            onPress={() => {
                                onItemPress(item, index);
                            }}
                        />
                    ))}
                </div>
                <div
                    className={styles.closeIconContainer}
                    onClick={() => handleClose()}
                >
                    <SlClose className={styles.closeIcon} />
                </div>
            </div>
        </div>
    );
};

export default PickerModal;
