import React, { FC } from 'react';
import clsx from 'clsx';
import { MdOutlineErrorOutline } from 'react-icons/md';
import Text from '../text/Text';
import styles from './AuthErrorCard.module.scss';

interface Props {
    children: React.ReactElement | string;
}

const AuthErrorCard: FC<Props> = ({ children }) => (
    <div
        className={clsx(styles.container, children ? styles.visible : styles.hidden)}
    >
        <div
            className={styles.iconContainer}
        >
            <MdOutlineErrorOutline
                className={styles.icon}
            />
        </div>
        <Text>
            {children}
        </Text>
    </div>
);

export default AuthErrorCard;
