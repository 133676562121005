import React, { useContext } from 'react';
import CompanyInfoContext from '../context/CompanyInfoContext';

const useCompanyInfo = () => {
const context = useContext(CompanyInfoContext);

if (!context) {
    throw new Error('useCompanyInfo must be used within a CompanyInfoProvider');
}

const {
    companyId,
    setCompanyId,
    companyData,
    setCompanyData,
    initialData,
    setInitialData,
    loading,
    setLoading,
    errors,
    setErrors,
    publishLoading,
    setPublishLoading,
} = context;

  return ({
    companyId,
    setCompanyId,
    companyData,
    setCompanyData,
    initialData,
    setInitialData,
    loading,
    setLoading,
    errors,
    setErrors,
    publishLoading,
    setPublishLoading,
  });
};

export default useCompanyInfo;
