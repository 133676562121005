import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginScreen from '../screens/LoginScreen';
import CompanyInformation from '../screens/CompanyInformation';
import ProtectedRoute from './ProtectedRoute';
import { CompanyInfoProvider } from '../context/CompanyInfoContext';

const AppNavigator = () => (
    <Routes>
        <Route path="/" element={<LoginScreen />} />
        <Route
            path="/company-information"
            element={(
                <ProtectedRoute>
                    <CompanyInfoProvider>
                        <CompanyInformation />
                    </CompanyInfoProvider>
                </ProtectedRoute>
            )}
        />
        <Route path="*" element={<LoginScreen />} />
    </Routes>
);

export default AppNavigator;
