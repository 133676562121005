import React, { useEffect } from 'react';
import styles from './App.module.scss';
import './input.css';
import { AuthProvider } from './context/AuthContext';
import AppNavigator from './navigation/AppNavigator';
import { RemoteConfigProvider } from './context/RemoteConfigContext';
import { ToastNotificationProvider } from './context/ToastNotificationContext';
import ToastNotificationCard from './components/cards/ToastNotificationCard';
import CookieBanner from './components/layout/CookieBanner';
import { isMobile } from 'react-device-detect';
import SellcorpBackgroundV2 from './components/layout/SellcorpBackgroundV2';

function App() {
  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl?.includes('heroku')) {
      window.location.href = 'https://app.sellcorporation.com/';
      return;
    }
    if (isMobile) {
      window.location.href = 'https://sellcorporationapp.page.link/founders';
    }
  }, []);

  return (
    <div className={styles.appContainer}>
      <SellcorpBackgroundV2 />
      <ToastNotificationProvider>
        <ToastNotificationCard />
        <RemoteConfigProvider>
          <AuthProvider>
            <AppNavigator />
          </AuthProvider>
        </RemoteConfigProvider>
      </ToastNotificationProvider>
      <CookieBanner />
    </div>
  );
}

export default App;
