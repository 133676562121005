import {
    Navigate,
} from 'react-router-dom';
import React, { FC } from 'react';
import useAuth from '../hooks/useAuth';

interface Props {
    children: React.ReactElement;
}

const ProtectedRoute: FC<Props> = ({ children }) => {
    const { isLoggedIn } = useAuth();

    if (isLoggedIn === null) {
        return <div className="text-white">Loading...</div>;
    }

    if (!isLoggedIn) {
        return <Navigate to="/" replace />;
    }

    return children;
};

export default ProtectedRoute;
