import React, {
    createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState,
} from 'react';
// import { CompanyInfoDataType } from '../types/CompanyInfo';
import AuthContext from './AuthContext';
import { CompanyInfoDataType, CompanyInfoKeyType } from '../types/CompanyInfoTypes';
import useAuth from '../hooks/useAuth';

type CompanyInfoErrorType = {
    [key in CompanyInfoKeyType]: string
}

const CompanyInfoContext = createContext<{
    companyId: null | false | string | undefined
    setCompanyId: Dispatch<SetStateAction<null | false | string | undefined>>
    companyData: CompanyInfoDataType
    setCompanyData: Dispatch<SetStateAction<CompanyInfoDataType>>
    initialData: CompanyInfoDataType | null
    setInitialData: Dispatch<SetStateAction<CompanyInfoDataType | null>>
    loading: boolean
    setLoading: Dispatch<SetStateAction<boolean>>
    errors: Partial<CompanyInfoErrorType>
    setErrors: Dispatch<SetStateAction<Partial<CompanyInfoErrorType>>>
    publishLoading: boolean
    setPublishLoading: Dispatch<SetStateAction<boolean>>
} | null>(null);

const { Provider, Consumer } = CompanyInfoContext;

export const CompanyInfoProvider: FC<any> = ({ children }) => {
    const { userEmail, userId } = useAuth();
    const [companyId, setCompanyId] = useState<null | false | string | undefined>();
    const [companyData, setCompanyData] = useState<Partial<CompanyInfoDataType>>({});
    const [initialData, setInitialData] = useState<Partial<CompanyInfoDataType> | null>(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<Partial<CompanyInfoErrorType>>({});
    const [publishLoading, setPublishLoading] = useState(false);

    const clearCompanyInfoContext = () => {
        setCompanyId(null);
        setCompanyData({});
        setInitialData(null);
        setLoading(false);
        setErrors({});
        setPublishLoading(false);
    };

    useEffect(() => {
        if (!userEmail) {
            clearCompanyInfoContext();
        }
    }, [userId, userEmail]);

    return (
        <Provider
            value={{
                companyId,
                setCompanyId,
                companyData,
                setCompanyData,
                initialData,
                setInitialData,
                loading,
                setLoading,
                errors,
                setErrors,
                publishLoading,
                setPublishLoading,
            }}
        >
            {children}
        </Provider>
    );
};

export default CompanyInfoContext;
