import { useEffect, useMemo, useState } from 'react';
import { isEmpty, keys } from 'lodash';
import moment from 'moment';
import {
    addDoc, collection, doc, getDoc, getDocs, setDoc, updateDoc,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { COMPANIES, PUBLISHED_COMPANIES, USERS } from '../consts/COLLECTIONS';
import useConfig from './useConfig';
import useNotification from './useNotification';
import { firestore, storage } from '../firebase/firebase';
import useAuth from './useAuth';
import { CompanyInfoDataType, CompanyInfoKeyType } from '../types/CompanyInfoTypes';
import useCompanyInfo from './useCompanyInfo';

const useGetCompany = (shouldFetchCompanyData: boolean, shouldCheckSaved: boolean) => {
    const { COMPANY_INFO_FIELDS } = useConfig();
    const { userId, userEmail } = useAuth();

    const {
        companyId,
        setCompanyId,
        companyData,
        setCompanyData,
        initialData,
        setInitialData,
        loading,
        setLoading,
        errors,
        setErrors,
        publishLoading,
        setPublishLoading,
    } = useCompanyInfo();

    const { setNotification } = useNotification();

    const [userPublishedCompany, setUserPublishedCompany] = useState<CompanyInfoDataType | null>(null);

    const canUnpublishCompany = useMemo(() => !!userPublishedCompany && !userPublishedCompany?.deactivated, [userPublishedCompany]);

    const getPublishedCompany = async () => {
        if (userId && companyId) {
            const dbCompanyData = await getDoc(doc(firestore, PUBLISHED_COMPANIES, companyId));
            const data: CompanyInfoDataType = { ...dbCompanyData?.data(), id: dbCompanyData?.id };
            if (!!data?.id && typeof data.deactivated === 'boolean') {
                setUserPublishedCompany(data);
            }
        }
    };

    const unpublishCompany = async (reason: string) => {
        try {
            if (userId && userPublishedCompany?.id && canUnpublishCompany) {
                const timeStamp = Date.now();
                const deactivationEvent = {
                    reason,
                    timestamp: +moment(),
                    date: moment().format('DD/MM/YYYY HH:mm:ss'),
                };

                let deactivationEvents;
                if (userPublishedCompany?.deactivationEvents?.length) {
                    deactivationEvents = [deactivationEvent, ...userPublishedCompany?.deactivationEvents];
                } else {
                    deactivationEvents = [deactivationEvent];
                }

                setPublishLoading(true);
                // await firestore().collection(PUBLISHED_COMPANIES).doc(userPublishedCompany?.id).update({
                //     deactivated: true,
                //     deactivationEvents,
                //     updatedAt: timeStamp,
                // });
                await updateDoc(doc(firestore, PUBLISHED_COMPANIES, userPublishedCompany?.id), {
                    deactivated: true,
                    deactivationEvents,
                    updatedAt: timeStamp,
                });

                setUserPublishedCompany({
                    ...userPublishedCompany,
                    deactivated: true,
                    deactivationEvents,
                    updatedAt: timeStamp,
                });
                setPublishLoading(false);
                setNotification({
                    id: 'companyUnpublished',
                    type: 'success',
                    message: 'Your company was unpublished successfully.',
                });
                await getPublishedCompany();
                // navigation.navigate(HOME_LANDING);
            }
        } catch (error) {
            setNotification({
                id: 'somethingWrong',
                type: 'error',
                duration: 5000,
                message: 'Something went wrong. Please contact support at info@sellcorporation.com',
            });
            console.log('error', error);
        }
    };

    useEffect(() => {
        if (userId && companyId) {
            getPublishedCompany();
        }
    }, [userId, companyId]);

    useEffect(() => {
        if (!isEmpty(companyData) && !!companyData?.contactEmail && !!companyData?.foundingDate && !initialData && keys(companyData)?.length > 1) {
            setInitialData(companyData);
        }
    }, [companyData, initialData]);

    // const hasDifferences = useMemo(() => {
    //     if (initialData && companyData && keys(companyData)?.length > 1) {
    //         return JSON.stringify(pickBy(initialData, (item) => !!item)) !== JSON.stringify(pickBy(companyData, (item) => !!item));
    //     }
    //     return false;
    // }, [companyData, companyData?.contactEmail, initialData]);
    // const hasDifferences = useMemo(() => {
    //     if (initialData && companyData) {
    //         // console.log('111111  ', JSON.stringify(pickBy(initialData, (item) => !!item)));
    //         // console.log('222222  ', JSON.stringify(pickBy(companyData, (item) => !!item)));
    //         console.log('111111  ', JSON.stringify(initialData));
    //         console.log('222222  ', JSON.stringify(companyData));

    //         // return JSON.stringify(pickBy(initialData, (item) => !!item)) !== JSON.stringify(pickBy(companyData, (item) => !!item));
    //         return JSON.stringify(initialData) !== JSON.stringify(companyData);
    //     }
    //     return false;
    // }, [companyData, companyData?.contactEmail, initialData]);

    // handle going back listener
    // useEffect(() => {
    //     if (shouldCheckSaved) {
    //         const unsubscribe = navigation.addListener('beforeRemove', (e) => {
    //             if (hasDifferences) {
    //                 e.preventDefault();
    //                 Alert.alert(
    //                     'Unsaved Changes',
    //                     'You have unsaved changes. Are you sure to discard them and leave the screen?',
    //                     [
    //                         { text: "Don't leave", style: 'cancel', onPress: () => { } },
    //                         {
    //                             text: 'Discard',
    //                             style: 'destructive',
    //                             onPress: () => navigation.dispatch(e.data.action),
    //                         },
    //                     ],
    //                 );
    //             }
    //         });
    //         return unsubscribe;
    //     }
    //     return undefined;
    // }, [navigation, hasDifferences]);

    // const getCompanies = async () => {
    //     console.log('companyId', companyId);
    //     console.log('userId', userId);

    //     if (userId && companyId) {
    //         // const dbCompanyData = await firestore().collection(USERS).doc(userId).collection(COMPANIES)
    //         //     .doc(companyId)
    //         //     .get();
    //         const dbCompanyData = await getDoc(doc(firestore, USERS, userId, COMPANIES, companyId));
    //         console.log('dbCompanyData', dbCompanyData);

    //         const data = { ...dbCompanyData?.data(), id: dbCompanyData?.id };
    //         console.log('ffff', { data });

    //         setCompanyData(data);
    //         setCompanyId(data?.id);
    //     }
    // };

    const saveImage = async (imageFile: File, storageKey: CompanyInfoKeyType) => {
        if (imageFile) {
            try {
                const filename = imageFile?.name;
                const randomFileName = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                const imageName = `${Date.now()}-${filename || randomFileName()}`;
                const imageRef = ref(storage, `users/${userId}/${companyId}/${storageKey}/${imageName}`);
                const snapshot = await uploadBytes(imageRef, imageFile, {
                    customMetadata: {
                        KEY: storageKey,
                    },
                });
                const downloadUrl = await getDownloadURL(snapshot.ref);
                return downloadUrl;
            } catch (err) {
                console.error('Error:', err);
                setNotification({
                    id: 'somethingWrong',
                    type: 'error',
                    duration: 5000,
                    message: 'Something went wrong. Please contact support at info@sellcorporation.com',
                });
            }
        }
    };

    const saveData = async () => {
        if (!loading && userId && keys(companyData)?.length) {
            setLoading(true);
            if (companyId) {
                try {
                    setInitialData(companyData);
                    const filesToSave: Partial<{
                        coverImage: string,
                        logo: string,
                        presentation: string,
                    }> = {};

                    const logoToSave = companyData?.logo_local;
                    const coverImageToSave = companyData?.coverImage_local;
                    const presentationToSave = companyData?.presentation_local;

                    if (logoToSave) {
                        const logoUrl = await saveImage(logoToSave, 'logo');
                        filesToSave.logo = logoUrl;
                    }

                    if (coverImageToSave) {
                        const coverImageUrl = await saveImage(coverImageToSave, 'coverImage');
                        filesToSave.coverImage = coverImageUrl;
                    }

                    if (presentationToSave) {
                        const presentationUrl = await saveImage(presentationToSave, 'presentation');
                        filesToSave.presentation = presentationUrl;
                    }

                    const newCompanyData = JSON.parse(JSON.stringify(companyData));
                    if (filesToSave?.coverImage) {
                        newCompanyData.coverImage = [];
                        newCompanyData.coverImage[0] = filesToSave.coverImage;
                        newCompanyData.coverImage_local = null;
                    }
                    if (filesToSave?.logo) {
                        newCompanyData.logo = [];
                        newCompanyData.logo[0] = filesToSave.logo;
                        newCompanyData.logo_local = null;
                    }
                    if (filesToSave?.presentation) {
                        newCompanyData.presentation = [];
                        newCompanyData.presentation[0] = filesToSave.presentation;
                        newCompanyData.presentation_local = null;
                    }
                    if (!newCompanyData?.contactEmail) {
                        newCompanyData.contactEmail = userEmail;
                    }

                    const companyDataToSave = JSON.parse(JSON.stringify(newCompanyData));

                    await updateDoc(doc(firestore, USERS, userId, COMPANIES, companyId), companyDataToSave).then(() => {
                        setCompanyData(companyDataToSave);
                        setLoading(false);
                    }).catch((err) => {
                        console.log({ err });
                    });
                    return true;
                } catch (error) {
                    setLoading(false);
                    // Handle toast notification
                    console.log({ error });
                    setNotification({
                        id: 'somethingWrong',
                        type: 'error',
                        duration: 5000,
                        message: 'Something went wrong. Please contact support at info@sellcorporation.com',
                    });
                }
            }
        }
    };

    const initCompany = async () => {
        if (!userId || !userEmail) return;

        const querySnapshot = await getDocs(collection(firestore, USERS, userId, COMPANIES)) as any;
        const data = [] as any;
        querySnapshot.forEach((document: any) => {
            data.push({ ...document.data(), id: document.id });
        });
        const foundCompany = data?.[0];

        if (foundCompany) {
            setCompanyId(foundCompany?.id);
            setCompanyData(foundCompany);
            setLoading(false);
        }

        if (!foundCompany) {
            try {
                const docRef = await addDoc(collection(firestore, USERS, userId, COMPANIES), {});
                if (docRef.id) {
                    setCompanyId(docRef.id);
                    setCompanyData({ id: docRef.id });
                    setLoading(false);
                }
            } catch (error) {
                console.log({ error });
                setNotification({
                    id: 'somethingWrong',
                    type: 'error',
                    duration: 5000,
                    message: 'Something went wrong. Please contact support at info@sellcorporation.com',
                });
            }
        }
    };

    const validateData = () => {
        let didFoundErrors = false;
        if (COMPANY_INFO_FIELDS?.length) {
            const newErrors = { ...errors };
            COMPANY_INFO_FIELDS.forEach((item) => {
                if (
                    (!item.SCOPE?.includes('primary') && companyData?.transactionType && (companyData?.transactionType?.[0] === 'eda8a559-d9c2-4f71-8fac-a70564b95aa8')) // primary
                    || (!item.SCOPE?.includes('secondary') && companyData?.transactionType && (companyData?.transactionType?.[0] === '3c81d8ea-2b36-40e2-a183-4d859cb2a563')) // secondary
                ) {
                    return null;
                }
                const tempData = companyData?.[`${item?.KEY}`];
                let localTempData;

                if (item?.KEY === 'logo' || item?.KEY === 'coverImage' || item?.KEY === 'presentation') {
                    localTempData = companyData?.[`${item?.KEY}_local`];
                }

                const validationData = tempData || localTempData;

                if (!item?.OPTIONAL
                    && (
                        !validationData
                        || (typeof validationData === 'number' && !validationData)
                        || (typeof validationData === 'string' && !validationData?.length)
                        || (Array.isArray(validationData) && !validationData?.length)
                    )
                ) {
                    console.log(tempData);
                    newErrors[`${item?.KEY}`] = item?.VALIDATION_ERROR;
                    didFoundErrors = true;
                }
            });
            setErrors(newErrors);
        }
        return didFoundErrors;
    };

    const publishCompany = async (noTimestamp?: boolean) => {
        const didFoundErrors = validateData();
        if (didFoundErrors) {
            setNotification({
                message: 'Please fill all the *required fields',
                type: 'warning',
                id: 'somethingWrong',
            });
        }
        if (!didFoundErrors && companyData?.id && userId) {
            try {
                const didSaveSuccessfully = await saveData();
                if (!didSaveSuccessfully) {
                    return;
                }
                setLoading(true);
                // fetch saved data from the user company
                // const dbCompanyData = await firestore().collection(USERS).doc(userId).collection(COMPANIES)
                //     .doc(companyData?.id)
                //     .get();
                const dbCompanyData = await getDoc(doc(firestore, USERS, userId, COMPANIES, companyData?.id));

                const data = { ...dbCompanyData?.data(), id: dbCompanyData?.id };

                // fetch the existing published company:
                // const existingpublishedData = await firestore().collection(PUBLISHED_COMPANIES).doc(companyData.id).get();
                const existingpublishedData = await getDoc(doc(firestore, PUBLISHED_COMPANIES, companyData.id));
                const existingData = existingpublishedData?.data();

                let timeStamp = Date.now();
                if (noTimestamp) {
                    timeStamp = existingData?.updatedAt;
                }
                if (existingData) {
                    // Update existing published company
                    const newData = {
                        ...existingData, ...data, updatedAt: timeStamp, deactivated: false,
                    };
                    // firestore().collection(PUBLISHED_COMPANIES).doc(companyData.id)
                    //     .update(JSON.parse(JSON.stringify(newData)))
                    //     .then(() => {
                    //         setLoading(false);
                    //     })
                    //     .catch((err) => {
                    //         console.log({ err });
                    //     });
                    try {
                        await updateDoc(doc(firestore, PUBLISHED_COMPANIES, companyData.id), JSON.parse(JSON.stringify(newData)));
                        setLoading(false);
                    } catch (err) {
                        console.log({ err });
                    }
                } else {
                    // Create new published company
                    const newData = {
                        ...data, updatedAt: timeStamp, publishedAt: timeStamp, deactivated: false,
                    };
                    // firestore().collection(PUBLISHED_COMPANIES).doc(companyData.id)
                    //     .set(JSON.parse(JSON.stringify(newData)))
                    //     .then(() => {
                    //         setLoading(false);
                    //     })
                    //     .catch((err) => {
                    //         console.log({ err });
                    //     });
                    try {
                        await setDoc(doc(firestore, PUBLISHED_COMPANIES, companyData.id), JSON.parse(JSON.stringify(newData)));
                        setLoading(false);
                    } catch (err) {
                        console.log({ err });
                    }
                }
                await getPublishedCompany();
            } catch (error) {
                console.log({ error });
                setLoading(false);
                setNotification({
                    id: 'somethingWrong',
                    type: 'error',
                    duration: 5000,
                    message: 'Something went wrong. Please contact support at info@sellcorporation.com',
                });
            }
            setLoading(false);
            setNotification({
                message: 'Your company was published successfully',
                type: 'success',
                id: 'companyPublished',
            });
            // navigation.navigate(HOME_LANDING);
        }
    };

    return ({
        companyData,
        setCompanyData,
        loading,
        errors,
        saveData,
        validateData,
        setErrors,
        initCompany,
        publishCompany,
        publishLoading,
        canUnpublishCompany,
        unpublishCompany,
    });
};

export default useGetCompany;
