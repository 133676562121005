import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import Box from '../components/layout/Box';
import Button from '../components/buttons/Button';
import AuthInput from '../components/inputs/AuthInput';
import Logo from '../components/Logo';
import SelectionTabs from '../components/buttons/SelectionTabs';
import styles from './LoginScreen.module.scss';
import AuthErrorCard from '../components/cards/AuthErrorCard';
import useAuth from '../hooks/useAuth';
import Text from '../components/text/Text';
import useConfig from '../hooks/useConfig';
// import ReCAPTCHA from 'react-google-recaptcha';

const tabs = ['Register', 'Login'];

export default function LoginScreen() {
    const [selectedTab, setSelectedTab] = useState(0);
    const { REGISTRATION_LEGAL } = useConfig();
    const [captchaCompleted, setCaptchaCompleted] = useState(true);

    const captchaToken = process.env.REACT_APP_ENVIRONMENT === 'development'
        ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST
        : process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    const {
        isLoggedIn,
        handleRegistration,
        handleLogIn,
        handleLogOut,
        userInfo,
        error,
        clearErrors,
        updateError,
        setUserInfo,
        resetPassword,
    } = useAuth();

    const errorString = [error.fullName, error.email, error.password, error.firebase].filter((e) => !!e).join('\n');

    if (isLoggedIn === null) {
        return (
            <div className="flex-col flex justify-center items-center">
                <Logo />
                <Text
                    style={{ marginTop: '20px' }}
                    white
                >
                    Loading...
                </Text>
            </div>
        );
    }

    if (isLoggedIn === true) {
        return (
            <Navigate to="/company-information" replace />
        );
    }

    return (
        <div
            className={styles.screenContainer}
        >
            <Logo animated />
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    if (!captchaCompleted) return;
                    if (selectedTab === 0) {
                        handleRegistration();
                    } else if (selectedTab === 1) {
                        handleLogIn();
                    }
                }}
                className={styles.formContainer}
            >
                <AuthErrorCard>
                    {errorString}
                </AuthErrorCard>
                <SelectionTabs
                    tabs={tabs}
                    activeTab={selectedTab}
                    setSelectedTab={(index) => {
                        clearErrors();
                        setSelectedTab(index);
                    }}
                />
                {selectedTab === 0 && (
                    <AuthInput
                        onChange={(e) => {
                            updateError('fullName', null);
                            setUserInfo({
                                ...userInfo,
                                fullName: e.target.value,
                            });
                        }}
                        value={userInfo.fullName}
                        title="Full Name"
                        placeholder="John Smith"
                    />
                )}
                <AuthInput
                    onChange={(e) => {
                        updateError('email', null);
                        setUserInfo({
                            ...userInfo,
                            email: e.target.value,
                        });
                    }}
                    value={userInfo.email}
                    title="Email"
                    placeholder="john.smith@domain.com"
                    type="email"
                />
                <AuthInput
                    onChange={(e) => {
                        updateError('password', null);
                        setUserInfo({
                            ...userInfo,
                            password: e.target.value,
                        });
                    }}
                    value={userInfo.password}
                    title="Password"
                    placeholder={'\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022'}
                    type="password"
                />
                {selectedTab === 1 && (
                    <Box
                        onPress={() => {
                            resetPassword();
                        }}
                    >
                        <Text
                            accent
                        >
                            Forgot password
                        </Text>
                    </Box>
                )}
                {selectedTab === 0 && Array.isArray(REGISTRATION_LEGAL?.DOCUMENTS) && (
                    <Box>
                        <div className={styles.legalDocs}>
                            By signing up, you acknowledge you have read and understood the following documents:
                        </div>
                        {REGISTRATION_LEGAL?.DOCUMENTS?.map((item) => (
                            <div
                                key={item.NAME}
                                className={styles.menuItem}
                            >
                                {'- '}
                                <a
                                    className={styles.link}
                                    href={item.URI}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {`${item.NAME}`}
                                </a>
                            </div>
                        ))}
                    </Box>
                )}
                <Button
                    type="submit"
                    style={{
                        marginTop: '20px',
                        backgroundColor: captchaCompleted ? '#1469A1' : 'grey',
                    }}
                >
                    {tabs[selectedTab]}
                </Button>
                <Box
                    center
                    flex
                    mt={20}
                >
                    {/* {!!captchaToken && (
                        <ReCAPTCHA
                            sitekey={captchaToken || ''}
                            onChange={(token) => {
                                if (token) {
                                    setCaptchaCompleted(true);
                                }
                            }}
                        />
                    )} */}
                </Box>
            </form>
        </div>
    );
}
