import React, { Dispatch, FC, SetStateAction } from 'react';
import { SlClose } from 'react-icons/sl';
import styles from './InfoModal.module.scss';

interface Props {
    infoModalText: false | { title: string, subtitle: string | string[] };
    setInfoModalText: Dispatch<SetStateAction<false | { title: string; subtitle: string | string[]; }>>;
}

const InfoModal: FC<Props> = ({ infoModalText, setInfoModalText }) => {
    if (!infoModalText) return null;
    return (
        <div
            className={styles.modal}
            onClick={() => setInfoModalText(false)}
        >
            <div
                className={styles.container}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.title}>
                    {infoModalText.title}
                </div>
                <div className={styles.subtitle}>
                    {Array.isArray(infoModalText.subtitle) ? (
                        infoModalText?.subtitle?.map((item) => (
                            <>
                                {item}
                                <br />
                            </>
                        ))
                    ) : (infoModalText.subtitle)}
                    {infoModalText.subtitle}
                </div>
                <div
                    className={styles.closeIconContainer}
                    onClick={() => setInfoModalText(false)}
                >
                    <SlClose className={styles.closeIcon} />
                </div>
            </div>
        </div>
    );
};

export default InfoModal;
