import React, { createContext, useEffect, useState } from 'react';
import { fetchAndActivate, getAll } from 'firebase/remote-config';
import defaultConfig, { ConfigType, ConfigTypeKeys } from '../config/config';
import { remoteConfig } from '../firebase/firebase';

const RemoteConfigContext = createContext<null | {
    config: Partial<ConfigType>,
}>(null);

const { Provider } = RemoteConfigContext;

type FeatureFlagProviderProps = {
    children: React.ReactNode,
};

// CHANGE TO FALSE FOR PRODUCTION!!!
// CHANGE TO FALSE FOR PRODUCTION!!!
// CHANGE TO FALSE FOR PRODUCTION!!!
const fetchDefaultValuesOnly = false;

const refetchSeconds = process.env.REACT_APP_ENVIRONMENT === 'development' ? 200 : 3600;

export const RemoteConfigProvider: React.FC<FeatureFlagProviderProps> = ({
    children,
}) => {
    const [config, setConfig] = useState<Partial<ConfigType>>({});
    const defaultConfigKeys = Object.keys(defaultConfig) as ConfigTypeKeys[];

    const fetchAll = async (debug = false) => {
        if (fetchDefaultValuesOnly) {
            setConfig(defaultConfig);
            return;
        }
        try {
            // await remoteConfig().setDefaults(defaultConfig);
            await fetchAndActivate(remoteConfig);

            // fetches and updates every 3.5 minutes as default.
            // await fetch(remoteConfig, 20000);

            const allConfigs = getAll(remoteConfig);
            const allConfigsKeys = Object.keys(allConfigs) as ConfigTypeKeys[];
            const allConfigsValues = Object.keys(allConfigs);

            if (allConfigsKeys?.length) {
                const parsedConfig: Partial<ConfigType> = {};
                allConfigsKeys?.forEach((item) => {
                    if (defaultConfigKeys.includes(item)) {
                        parsedConfig[item] = JSON.parse(allConfigs[item].asString());
                    }
                });
                if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                    setConfig(defaultConfig); // Use for local values
                } else {
                    setConfig(parsedConfig); // Use for remote values
                }
                return;
            }
            setConfig(defaultConfig);
        } catch (e) {
            setConfig(defaultConfig);
            console.log('[Firebase Config Provider] - Error', e);
        }
    };

    useEffect(() => {
        (async () => {
            await fetchAll(false);
        })();
    }, []);

    return (
        <Provider value={{ config }}>
            {children}
        </Provider>
    );
};

export default RemoteConfigContext;
