import React, { useState } from 'react';
import { FaRegCircleUser } from 'react-icons/fa6';
import { FaAppStoreIos } from 'react-icons/fa';
import { IoLogoGooglePlaystore } from 'react-icons/io5';
import styles from './NavBar.module.scss';
import Logo from '../Logo';
import useAuth from '../../hooks/useAuth';
import useConfig from '../../hooks/useConfig';
import iosQRCode from '../../assets/images/ios-qr-code.png';
import androidQRCode from '../../assets/images/android-qr-code.png';

const NavBar = () => {
    const { userEmail, handleLogOut } = useAuth();
    const [menuActive, setMenuActive] = useState(false);
    const [showIOSStore, setShowIOSStore] = useState(false);
    const [showAndroidStore, setShowAndroidStore] = useState(false);
    const { REGISTRATION_LEGAL, INVESTOR_LEGAL } = useConfig();

    return (
        <div
            className={styles.container}
        >
            {/* <div className={styles.spacer} /> */}
            <Logo
                withText
                style={{
                    width: '40px',
                    height: '40px',
                }}
            />
            <div className={styles.rightIconContainer}>
                <div
                    onMouseEnter={() => {
                        setShowIOSStore(true);
                        setShowAndroidStore(false);
                        setMenuActive(false);
                    }}
                >
                    <FaAppStoreIos className={styles.appStoreIcon} />
                    {showIOSStore && (
                        <div
                            className={styles.iosQrCodeContainer}
                            onMouseLeave={() => {
                                setShowIOSStore(false);
                            }}
                        >
                            <a
                                href="https://apps.apple.com/ro/app/sellcorp/id6446894441"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className={styles.qrCode}
                                    src={iosQRCode}
                                />
                                <div className={styles.qrCodeText}>
                                    SellCorp on App store
                                </div>
                            </a>

                        </div>
                    )}
                </div>
                <div
                    onMouseEnter={() => {
                        setShowAndroidStore(true);
                        setShowIOSStore(false);
                        setMenuActive(false);
                    }}
                >
                    <IoLogoGooglePlaystore className={styles.playStoreIcon} />
                    {showAndroidStore && (
                        <div
                            className={styles.androidQrCodeContainer}
                            onMouseLeave={() => {
                                setShowAndroidStore(false);
                            }}
                        >
                            <a
                                href="https://play.google.com/store/apps/details?id=com.sellcorporationapp&hl=en_US"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className={styles.qrCode}
                                    src={androidQRCode}
                                    width={200}
                                    height={200}
                                />
                                <div className={styles.qrCodeText}>
                                    SellCorp on Play store
                                </div>
                            </a>
                        </div>
                    )}
                </div>
                <div>
                    <FaRegCircleUser
                        className={styles.userIcon}
                        onMouseEnter={() => {
                            setMenuActive(true);
                            setShowIOSStore(false);
                            setShowAndroidStore(false);
                        }}
                    />
                    {menuActive && (
                        <div
                            onMouseLeave={() => {
                                setMenuActive(false);
                            }}
                            className={styles.rightContainer}
                        >
                            <div className={styles.email}>
                                {userEmail}
                            </div>
                            <div className={styles.menuTitle}>
                                Investor documents:
                            </div>
                            {INVESTOR_LEGAL.DOCUMENTS.map((item) => (
                                <div
                                    key={item.NAME}
                                    className={styles.menuItem}
                                >
                                    <a
                                        href={item.URI}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {item.NAME}
                                    </a>
                                </div>
                            ))}
                            <div className={styles.menuTitle}>
                                Legal documents:
                            </div>
                            {REGISTRATION_LEGAL.DOCUMENTS.map((item) => (
                                <div
                                    key={item.NAME}
                                    className={styles.menuItem}
                                >
                                    <a
                                        href={item.URI}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {item.NAME}
                                    </a>
                                </div>
                            ))}
                            <div
                                className={styles.logoutButton}
                                onClick={handleLogOut}
                            >
                                Log out
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

export default NavBar;
