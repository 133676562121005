import React, { FC } from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { LuCalendarDays } from 'react-icons/lu';
import styles from './TextInput.module.scss';
import Text from '../text/Text';
import { CompanyInfoInputType } from '../../types/CompanyInfoTypes';
import FieldTitle from '../text/FieldTitle';

interface Props {
    type: CompanyInfoInputType,
    autoCapitalize: any,
    inputType?: 'text' | 'date' | 'number',
    inputStyle: any,
    keyboardType: any,
    value: any,
    selectedUnit: any,
    storageKey: any,
    setCompanyData: any,
    setErrors: any,
    companyData: any,
    selected: any,
    placeholder: any,
    label: any,
    conditionLabel: any,
    defaultValue: any,
    inputHeight: any,
    info: any,
    optional: any,
    error: any,
    multiline: any,
    maxLength: any,
    imageWidth: any,
    imageHeight: any,
    toggleTitle: any,
    iconName: any,
    iconFamily: any,
    iconSize: any,
    disclaimerLabel: any,
    disclaimerIconName: any,
    disclaimerIconFamily: any,
    disclaimerIconSize: any,
    onInfoPress: any,
    onChangeText: any,
    onPress: any,
}

const TextInput: FC<Props> = ({
    type,
    autoCapitalize,
    inputType,
    inputStyle,
    keyboardType,
    value,
    selectedUnit,
    storageKey,
    setCompanyData,
    setErrors,
    companyData,
    selected,
    placeholder,
    label,
    conditionLabel,
    defaultValue,
    inputHeight,
    info,
    optional,
    error,
    multiline,
    maxLength,
    imageWidth,
    imageHeight,
    toggleTitle,
    iconName,
    iconFamily,
    iconSize,
    disclaimerLabel,
    disclaimerIconName,
    disclaimerIconFamily,
    disclaimerIconSize,
    onInfoPress,
    onChangeText,
    onPress,
}) => {
    const limitReached = value?.length >= maxLength;

    return (
        <div className={styles.container}>
            <FieldTitle
                optional={optional}
                onInfoPress={onInfoPress}
                label={label}
            />
            <div className={styles.inputContainer}>
                {type === 'moneyInput' && (
                    <div
                        className={styles.unit}
                        onClick={() => {
                            onPress();
                        }}
                    >
                        {selectedUnit || 'GBP'}
                    </div>
                )}
                {multiline ? (
                    <textarea
                        value={value}
                        onChange={(e) => {
                            const val = e?.target?.value;
                            onChangeText(val);
                        }}
                        placeholder={placeholder}
                        autoCapitalize={autoCapitalize}
                        style={{ height: inputHeight }}
                        className={styles.input}
                        maxLength={maxLength}
                    />
                ) : (
                    <input
                        value={value}
                        onChange={(e) => {
                            const val = e?.target?.value;
                            onChangeText(val);
                        }}
                        placeholder={placeholder}
                        autoCapitalize={autoCapitalize}
                        style={{ height: inputHeight }}
                        className={styles.input}
                        type={inputType}
                        maxLength={maxLength}
                    />
                )}
                {inputType === 'date' && (
                    <LuCalendarDays className={styles.calendarIcon} />
                )}
            </div>
            <div className={styles.bottomContainer}>
                {!!error && (
                    <div className={styles.error}>
                        {error}
                    </div>
                )}
                {/* don't delete the empty div */}
                <div />
                {!!maxLength && (
                    <div
                        className={limitReached ? styles.charLimitReached : styles.charLimit}
                    >
                        {`${value?.length || 0}/${maxLength}`}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TextInput;
